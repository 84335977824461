@font-face {
  font-family: "Mario Text";
  src: url('./fonts/PressStart2P-vaV7.ttf');

}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Mario Text";
  user-select: none;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  width: 100%;
  height: 500vh;
  
}
.flex {
  display:flex;
  align-items:center;
  justify-content: center;
  min-width: fit-content;
  width:300%;
  transform: rotate(90deg) translateY(-100vh);
  transform-origin: top left;
}
.home {
  display:flex;
  align-items: center;
  width: 200%;
  height:100vh;
  background: url('./images/mario.webp') repeat;
  background-size:contain;
  gap: 1%;
}

.wrapper {
  width: 300vw;
  height:100vw;
  transform: rotate(-90deg) translateX(-100vh);
  transform-origin: top left;
  overflow-y: scroll; 
  overflow-x: none;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
::-webkit-scrollbar{
  display:none;
}
#root{
  height: 0;
}

.mariostand, .mariostandBack, .mario1, .mario2, .mario3, .mario1-back, .mario2-back, .mario3-back, .mario-jump, .mario-jumpback{
  display:flex;
  width: 96px;
  height: 192px;
  background-position: center;
  background-size:cover;
  position:fixed;
  background-repeat: no-repeat;
  justify-self: 'flex-start';
  align-self:flex-end;
  bottom: 13vh;
}


.mario-jump {
  background-image: url('./images/mario-jump.png')
}
.mario-jumpback {
  background-image: url('./images/mario-jumpback.png')
}
.mariostand {
  background-image: url('./images/mariostand.png');
}
.mario1{
  background-image: url('./images/mario-walk1.png');
}
.mario2{
  background-image: url('./images/mario-walk2.png');
}
.mario3{
  background-image: url('./images/mario-walk3.png');
}
.mariostandBack{
  background-image: url('./images/mariostandback.png');
}
.mario1-back{
  background-image: url('./images/mario-walkback1.png');
}
.mario2-back{
  background-image: url('./images/mario-walkback2.png');
}
.mario3-back{
  background-image: url('./images/mario-walkback3.png');
}
.about-flex{
  display:flex;
  flex-flow: column wrap;
}
#form > fieldset{
  display:flex;
  justify-self:center;
  flex-flow: column wrap;
  text-align: left;
  gap: 5vh;
  margin-top: 2rem;   
  padding: 5%;  
  height: 86.5%;
}
.center {
  text-align: center;
}
#form label {
  width: 50%;
}
.message-label{
  width: 75% !important;
  height: 35% !important;
  display:flex;
  align-self:center;
}
#form input {
  width: 100%;
  height: 32px;
  padding: 1rem;
}
#form button {
  width: 50%;
  height: 32px;
  align-self: center;
}
#form textarea {
  resize: none; 
  padding: .5rem;
}
.broken {
  width: 80px;
  height: 80px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  z-index: 2;
  background-image: url('./images/question-block.png');
  animation: brokenBlock .25s linear;
  animation-fill-mode: forwards;
  top: 40vh;
  position:fixed;
  align-self: flex-start;
 }
.block {
    background-image: url('./images/question-block.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position:fixed;
    width: 80px;
    height: 80px;
    top: 40vh;
    align-self: flex-start;

}
@keyframes brokenBlock{
  0% {
    transform: translateY(0);
    background-image: url('./images/question-block.png')
  }
  50% {
    transform: translateY(-50%)
  }
  100% {
    transform: translateY(0);
    background-image: url('./images/hit-block.png')
  }
}
h1 {
  border: 3px solid;
  border-style:dashed;
  text-align: center;
  margin-left: 100vw;
  width: 100vw;
}
.coin, .nocoin{
  background-image: url('./images/mario-coin.gif');
  background-size: contain;
  background-repeat: no-repeat;
  width: 5vw;
  height: 5vh;
  position:fixed;
  bottom: 40vh;
}
.nocoin {
    opacity: 0;
}
#about-open, #skills-open, #form-open {
  display:flex;
  height: 100vh;
  gap: 10px;
  padding: 1rem;
  flex-direction: column;
  justify-content:flex-start;
  align-items: center;
  line-height:1.5;
}
#form-open {
  flex-direction: row;
}
@keyframes showAbout {
  from { opacity: 0; }
  to { opacity: 1; }
}
.show-about, .hide-about, .show-skills, .hide-skills {
  width: 70vw;
  height: fit-content;
  opacity: 0;
  background-color:black;
  padding: 2.5vw;
  border-top-right-radius:10%;
  border-bottom-left-radius: 5%;
  margin-top: 5vh;
  margin-left: 1em;
  text-align:justify;
  font-size: 16px;
  color: white;
  letter-spacing: 1px;
}
.show-about p, .show-skills p {
  padding: 1rem;
}

.show-about, .show-skills {
    animation-name: showAbout;
  animation-delay: 0.5s;
  animation-duration: .75s;
  animation-fill-mode: forwards;
}
.hide-form {
  opacity: 0;
  visibility:hidden;
}
fieldset {
  margin: 0;
}
#form-open {
  display: block;
}
.show-form {
  animation-name: showAbout;
  animation-delay: 0.5s;
  animation-duration: 0.75s;
  animation-fill-mode: forwards;
  opacity: 0;
  margin-left: 10rem;
}
.welcome {
  margin-left: 3%;
  max-width: 100vw;
  min-width: 25vw;
  text-align: center;
}

.triangle {
  width: 0; 
  height: 0; 
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  background-color: transparent;
  border-bottom: 25px solid rgb(119, 119, 119);
  pointer-events: none;
}
a {
  text-decoration: none;
  color: red;
}
.gamepad {
  user-select: none;
  width: 150px;
  display:flex;
  position: fixed;
  bottom: .5vh;
  left: 100px;
  user-select: none;
  z-index: 1;
  flex-wrap: wrap;
}
.gamepad:nth-of-type(odd){
  background-color: transparent;
}
.gamepad > div {
  touch-action: none;
  width: 50px;
  height: 50px;
}
.right > .triangle {
  transform: rotate(90deg)
}
.left > .triangle {
  transform: rotate(-90deg)
}
.left:active, .right:active, .up:active {
  background-color: rgb(185, 184, 184);
  border-width:1px;
  transform: scale(90%)
}
.left, .right, .up {
  background-color: black;
  display:flex;
  justify-content: center;
  align-items: center;
  padding:1%;
  width: 100px;
  height: 100px;
}
@keyframes textAnimation{
  0% { opacity: 0; transform: translateY(-500vh); }
  25% { opacity: 0.25; transform: translateY(-50vh);}
  50% { opacity: 0.5; }
  75% { opacity: 0.75; }
  100% { opacity: 1; }
}
.text-animation {
  animation-name: textAnimation;
  opacity: 0;
  animation-delay: 0.5s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  width: 50vw;
  text-align: center;
  margin-left: 50vw;
  margin-right: 20vw;
  letter-spacing: 1px;
}
@media screen and (max-width: 550px){
  .mariostand, .mariostandBack, .mario1, .mario2, .mario3, .mario1-back, .mario2-back, .mario3-back, .mario-jump, .mario-jumpback {
    width: 64px;
    height: 128px;
  }
  .text-animation {
    width: 75vw;
  }
  .show-about, .show-skills, .show-form {
    font-size: 12px;
    max-width: 75vw;
  }
  fieldset {
    margin-left: 150px;
  }
  .broken, .block {
    top: 50vh !important;  
    width: 60px;
    height: 60px;
  }
  .gamepad {
    width: 90px !important;
  }
  .gamepad > div {
    width: 30px !important;
    height: 30px !important;
  }
  .triangle {
    border-bottom-width: 10px !important;
  }

}